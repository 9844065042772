import React, {Component} from "react"

import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import Accordion from "../../components/accordion/accordion";

import './mitglieder.sass'
import {graphql} from "gatsby";
import BecomeMemberSmall from "../../components/become-member-small/become-member-small";
import Member from "../../components/member/member";

export default class MitgliederBranchenPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            query: ``,
        }
    }

    render() {
        const { data } = this.props;

        let filtered = data.groupedMembers.group;

        const regex = /[^\w]/g;
        if (this.state.query) {
            filtered = filtered.filter(item => {
                return item.fieldValue.replace(regex, '').toLowerCase().includes(this.state.query.replace(regex, '').toLowerCase());
            });
        }

        filtered.sort((a, b) => {
            const aString = a.fieldValue.replace(regex, '').toLowerCase();
            const bString = b.fieldValue.replace(regex, '').toLowerCase();
            if (aString < bString)
                return -1;
            if (aString > bString)
                return 1;
            return 0;
        });

        return (
            <Layout>
                <SEO title="Mitglieder"/>
                <div className={'memberPage container section'}>

                    <h1 className={'title'}>Mitglieder</h1>
                    <h4 className={'subtitle'}>Mitglieder von A bis Z</h4>
                    <div className="text-container memberSwitch">
                        <p><a href="/mitglieder">A-Z</a>&nbsp;-&nbsp;Branchen</p>
                        <input className={'search-input'} type="text" placeholder={"Suche"} value={this.state.query} onChange={this.search} />
                    </div>
                    <div className="fourth-container">
                        {filtered.map((item, i) => {
                            return (<Accordion key={i} title={ item.fieldValue }>
                                {
                                    item.edges ? item.edges.map((member, j) => (
                                        <Member key={j} node={member.node}/>
                                    )) : ""
                                }
                            </Accordion>)
                        })}
                    </div>
                </div>
                <BecomeMemberSmall></BecomeMemberSmall>
            </Layout>
        );
    }



    search = event => {
        const query = event.target.value;
        this.setState(s => {
            return {
                query,
            }
        })
    }

}


export const pageQuery = graphql`
    query MembersBranchesQuery {
      groupedMembers: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "//members//"}}) {
        group(field: frontmatter___branche) {
          fieldValue
          edges {
            node {
              html
                rawMarkdownBody
                frontmatter {
                  title
                  image
                  image_label
                  body_right
                }
            }
          }
        }
      }
    }
  `;
